<template>
  <div class="create-clue">
    <SelectShop></SelectShop>
    <van-form
      ref="createForm"
      input-align="right"
      error-message-align="right"
      @submit.native.prevent>
      <van-collapse v-model="activeNames" style="padding: 16px;">
        <van-collapse-item class="form-collapse-item" :title="$t('线索信息')" :name="1">
          <!-- 手机号码 -->
          <van-field
            v-model.trim="formData.userMobile"
            required
            type="number"
            :label="$t('联系电话')"
            :placeholder="$t('请输入联系电话')"
            maxlength="11"
            :rules="userMobileCheck"
          />
          <!-- 微信 -->
          <van-field
            label-class="van-field-label"
            :label="$t('客户微信')"
            :placeholder="$t('请选择一个微信好友')"
            :value="formData.wechatInfo.name"
            readonly>
            <template #button>
              <div style="display: flex;">
                <div
                  v-if="!formData.wechatInfo.externalUserid"
                  class="btn-item active commonActive"
                  @click="openAddCustomer"
                >
                  {{ $t("添加") }}
                </div>
                <div
                  class="btn-item active commonActive"
                  @click="selectExternalContact"
                >
                  {{ $t("绑定") }}
                </div>
              </div>
            </template>
          </van-field>
          <!-- 客户姓名 -->
          <van-field
            v-model.trim="formData.userName"
            required
            :label="$t('客户姓名')"
            maxlength="20"
            :placeholder="$t('请输入客户姓名')"
            :rules="[{ required: true, trigger: 'onBlur' }]"
          />
          <!-- 来源渠道 -->
          <van-field
            :label="$t('来源渠道')"
            required
            :placeholder="$t('请选择来源渠道')"
            :value="formData.customerSource.displayName"
            right-icon="arrow"
            type="textarea"
            autosize
            rows="1"
            readonly
            :rules="[{ required: true, trigger: 'onBlur' }]"
            @click="onClueSourceSelect"
          />
          <!-- 关联进店客户 -->
          <!-- <van-field
            v-if="showRoom"
            :label="$t('关联进店客户')"
            right-icon="arrow"
            readonly
            @click="openRoom">
            <template #input>
              <span style="color: #b9921a">{{ roomSuccess }}</span>
            </template>
          </van-field> -->
          <!-- 客户性别 -->
          <div class="common-cell">
            <span class="common-cell-label">{{
              $t("客户性别")
            }}</span>
            <div class="common-cell-value flex-wrap-clue">
              <div
                v-for="(item, index) in genderList"
                :key="index"
                class="btn-item"
                :class="{ commonActive: item.dictCode === formData.userSex }"
                @click="formData.userSex = item.dictCode"
              >
                {{ item.dictName }}
              </div>
            </div>
          </div>
          <!-- 客户类型 -->
          <div class="common-cell">
            <span class="common-cell-label">{{
              $t("客户类型")
            }}</span>
            <div class="common-cell-value flex-wrap-clue">
              <div
                v-for="(item, index) in customerTypeList"
                :key="index"
                class="btn-item"
                :class="{ commonActive: item.dictCode === formData.userType }"
                @click="formData.userType = item.dictCode"
              >
                {{ item.dictName }}
              </div>
            </div>
          </div>
          <!-- 公司名称 -->
          <van-field
            v-if="formData.userType === 2"
            v-model.trim="formData.companyName"
            :label="$t('公司名称')"
            maxlength="50"
            :placeholder="$t('请输入')"
          />
          <template v-if="isShopWorkRole">
            <!-- 意向信息 -->
            <van-field
              ref="carInfo"
              :label="$t('意向车型')"
              :placeholder="$t('请选择意向信息')"
              :value="formData.carInfo | formatCarInfo"
              right-icon="arrow"
              type="textarea"
              rows="1"
              autosize
              readonly
              :error-message="carInfoErrMsg"
              @click="selectCarModel"
            />
            <!-- 试驾意向等级 -->
            <van-field
              :label="$t('试驾意向等级')"
              :placeholder="$t('请选择试驾意向等级')"
              :value="formData.tryDriveLevelName"
              right-icon="arrow"
              readonly
              input-align="right"
              required
              :rules="[{required:true}]"
              @click="selectField('tryDriveLevel', 'tryDriveLevelName', 1012, false)">
            </van-field>
            <van-field
              :label="$t('销售顾问')"
              :placeholder="$t('请选择分配顾问')"
              :value="formData.followUserName"
              right-icon="arrow"
              type="textarea"
              autosize
              rows="1"
              readonly
              @click="distributionNext"
            />
          </template>
          <!-- 下次跟进时间 -->
          <van-field
            :value="formData.planTime | timeFormat"
            required
            :label="$t('下次跟进时间')"
            :placeholder="$t('请选择下次跟进时间')"
            right-icon="arrow"
            readonly
            input-align="right"
            :rules="[{ required: true }]"
            @click="selecNextFollowTime"
          >
          </van-field>
          <van-field
            v-model.trim="formData.followRemark"
            :label="$t('备注')"
            rows="2"
            autosize
            type="textarea"
            maxlength="200"
            :placeholder="$t('请输入')"
            show-word-limit
            required
            :rules="[{ required: true }]"
          />
          <!-- <FormVue v-if="showMoreInfo" :isEdit="true" :labelWidth="labelWidth" :list="info" :formData="formData" /> -->
          <van-cell v-if="!showMoreInfo">
            <template #default>
              <div class="complete" @click="improveUser">
                {{ $t("完善客户信息") }}
              </div>
            </template>
          </van-cell>
        </van-collapse-item>
        <!-- http://jira.inner.vwdsso.com/browse/SCRMSAL-390?filter=-1 所有角色新建线索都不要求强制填写首次跟进记录-->
        <!-- <van-collapse-item v-if="!isShopWorkRole" class="form-collapse-item" title="首次跟进记录" :name="2">
          <FollowInfo ref="followInfo" :from="'create'" @changeTypeCode="changeTypeCode"></FollowInfo>
        </van-collapse-item> -->
        <!-- <van-collapse-item v-if="!isShopWorkRole" class="form-collapse-item" title="首次跟进记录" :name="3">
          <FormVue :isEdit="true" :labelWidth="labelWidth" :list="buyCarInfo" :formData="formData" />
          <van-cell>
              <template #default>
                <div class="complete" @click="collapse">
                  {{ $t("收起") }}
                </div>
              </template>
            </van-cell>
        </van-collapse-item> -->
        <!-- 画像标签数据 -->
        <div v-show="showMoreInfo" style="margin-top: 16px;">
          <van-collapse-item v-for="(item, index) in infos" :key="index" class="form-collapse-item" :title="item.name" :name="index+2">
            <EditInformation :ref="`editInformation_${index}`" :title="''" :fromModule="'createClue'" :dictionaries="dictionaries" :details="item.items" :leadId="''" :showBtn="false"></EditInformation>
            <van-cell v-if="index + 1 == infos.length">
              <template #default>
                <div class="complete" @click="collapse">
                  {{ $t("收起") }}
                </div>
              </template>
            </van-cell>
          </van-collapse-item>
        </div>
      </van-collapse>
      <BusinessFilters
        v-if="showDistribution"
        ref="filter"
        v-model="showDistribution"
        :options="distributionOptions"
        :defaultActiveKey="defaultActiveKey"
        :buttonNameReset="$t('取消')"
        :buttonNameOK="$t('确认')"
        @reset="showDistribution = false"
        @submit="distributionFilter">
      </BusinessFilters>
      <div class="common-footer">
        <van-button class="btn" native-type="button" @click.stop="draftBtn">{{
          $t("暂存")
        }}</van-button>
        <van-button
          v-if="showSaveAndFollow"
          class="btn"
          :loading="submitLoading1"
          type="submit"
          @click="onSubmit(1)" 
          >{{ $t("保存并跟进") }}</van-button
        >
        <van-button
          class="btn submit"
          :loading="submitLoading2"
          type="submit"
          @click="onSubmit(2)"
          >{{ $t("保存") }}</van-button
        >
      </div>
    </van-form>
    <Room v-if="roomDialogShow" @close="closeRoomDialog"></Room>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { groupBy, isString } from 'lodash'
import BtnVoiceInput from '@/components/btn-voice-input'
import { mapGetters } from 'vuex'
import { validate } from '@/utils/validate'
import selectClueSource from '@/components/select-clue-source'
import selectCar from '@/components/select-car'
import FollowInfo from '../../components/FollowInfo'
import clueServices from '@/services/clueServices'
import baseDataServices from '@/services/baseDataServices'
import loading from '@/utils/loading'
import store from '@/store'
import { dateFormat, getArrToOBj } from '@/utils'
import { ROLES } from '@/constants/constants'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
import moment from 'moment'
import commonSelector from '@/components/common-selector'
import FormVue from '@/modules/components/form/index.vue'
import AddressSystem from '@/components/addressSystem.vue'
import EditInformation from '../component/tags/EditInformation.vue'
import { bigCustomList, isReplaceLIst, isEvaluateLIst, isChargeList, isParkingSpaceList, vehiclePlateList } from '../config'
import { i18n } from '@/main'
import Room from '@/components/room'
import SelectShop from '@/components/SelectShop/index.vue'

export default {
  name: 'createClue',
  filters: {
    formatCarInfo(info = {}) {
      const { seriesName, modelName } = info
      let display = ''
      seriesName && (display += ` ${seriesName}`)
      modelName && (display += ` ${modelName}`)
      return Object.keys(info).length === 0 ? null : display
    },
    timeFormat(val) {
      if (!val) return ''
      return moment(val).format('YYYY-MM-DD')
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path != '/edit-clue') {
        store.commit('app/setTemplateInfo', {})
      }
      if (from.path !== '/clue-list') {
        vm.fromClueList = false
      }
    })
  },
  components: {
    BtnVoiceInput,
    FollowInfo,
    FormVue,
    BusinessFilters,
    AddressSystem,
    EditInformation,
    Room,
    SelectShop
  },
  data() {
    const that = this
    return {
      validate,
      activeNames: [1,2,3,4,5],
      formData: {
        userMobile: '',
        wechatInfo: {}, // 微信信息
        userName: '',
        customerSource: {
          data: [{
            id: '1753208491511615490',
            text: this.$t('自然进店'),
          },{
            id: '1753210199448981506',
            text: this.$t('门店新建')
          }],
          displayName: `${this.$t('自然进店')} ${this.$t('门店新建')}`
        }, // 来源渠道
        userSex: '',
        userType: '',
        companyName: '',
        followUserId: '',
        followUserName: '', // 分配顾问
        planTime: dayjs().add(1, 'days').format('YYYY-MM-DD 23:59:59'), // 下次跟进时间 默认次日
        tryDriveLevelName: '',
        tryDriveLevel: '',
        followRemark: '',
        // 新增的字段
        isMajorCustomer: '',
        userAddress: '',
        userCity: '',
        userCityCode: '',
        userCounty: '',
        userCountyCode: '',
        userProvince: '',
        userProvinceCode: '',
        isReplace: '',
        isParkingSpace: '',
        vehiclePlate: '',
        plateSource: '',
        useType: '',
      },
      carInfoErrMsg: '',
      genderList: [
        {
          dictName: this.$t('男'),
          dictCode: 1,
        },
        {
          dictName: this.$t('女'),
          dictCode: 2,
        },
      ],
      customerTypeList: [
        {
          dictName: this.$t('个人购车'),
          dictCode: 1,
        },
        {
          dictName: this.$t('公司购车'),
          dictCode: 2,
        },
      ],
      judgeExists: false,
      showDistribution: false,
      distributionOptions: [
        {
          name: '',
          type: 'B_PEOPLE_SELECTION',
          field: 'saIdList',
          isSelection: true,
          valueType: 'object',
          shopIds: '',
          height: '80vh',
          roleCodes: [ROLES.DCC, ROLES.SALES],
          withPermission: true, // 仅查询权限范围内的
        },
      ],
      defaultActiveKey: ['saIdList'],
      submitLoading1: false,
      submitLoading2: false,
      fromClueList: true,
      buyCarInfo: [
        {
          label: this.$t('置换需求'),
          field: 'isReplace',
          type: 'tags',
          options: isReplaceLIst,
        },
        {
          label: this.$t('居住小区'),
          field: 'chargeAddress',
          type: 'input',
          maxlength: '50',
          get isHidden() {
            return !that.formData.isCharge
          },
        },
        {
          label: this.$t('是否有固定车位'),
          field: 'isParkingSpace',
          type: 'tags',
          options: isParkingSpaceList,
        },
        {
          label: this.$t('购车用途'),
          field: 'useType',
          type: 'select',
          get value() {
            const { formData, getUseType } = that
            console.info('formData.useType', formData.useType)

            let str = (formData.useType)
              .split(';')
              .map(i => getUseType[i]?.name)
              .filter(Boolean)
              .join(i18n.t('、'))

            return str || ''
          },
          click: (key, item) => {
            that.selectField(key, '', '1003', true)
          }
        },
        {
          label: this.$t('车辆指标类型'),
          field: 'vehiclePlate',
          type: 'tags',
          options: vehiclePlateList,
        },
        {
          label: this.$t('车辆指标来源'),
          field: 'plateSource',
          type: 'select',
          get value() {
            const { formData, getPlateSource } = that
            console.info('formData.plateSource', formData.plateSource)

            return getPlateSource[formData.plateSource]?.name
          },
          click: (key, item) => {
            that.selectField(key, '', '1029', false)
          }
        },
      ],
      info: [
        {
          label: this.$t('是否大客户'),
          field: 'isMajorCustomer',
          type: 'tags',
          options: bigCustomList,
        },
        {
          label: this.$t('常住城区'),
          field: '',
          type: 'render',
          renderFn(h, item) {
            return <AddressSystem isDisabled={false} formData={that.formData} label={that.$t('常住城区')} inputAlign="right" labelWidth='7.2em' on-change={(val) => { that.formData = val }} />
          },
          get value() {
            const { userCity, userProvince, userCounty } = that.formData
            const arr = []
            userProvince && arr.push(userProvince)
            userCity && arr.push(userCity)
            userCounty && arr.push(userCounty)
            return arr.join('/')
          },
        },
        {
          label: this.$t('居住小区'),
          field: 'userAddress',
          type: 'textarea',
          maxlength: '50',
        },
      ],
      labelWidth: '9.2em',
      showMoreInfo: false, // 展示完善信息
      infos: [], // 当前线索标签
      dictionaries: [], // 所有标签字典数据
      roomDialogShow: false,
      roomSuccess: '',
    }
  },
  computed: {
    ...mapGetters([
      'shops',
      'dictHash',
      'userInfo',
      'templateClueInfo',
      'selectedShopId'
    ]),
    // 客户手机号验证
    userMobileCheck() {
      // 跟进方式-展厅接待可不填手机号
      const { userMobile, wechatInfo } = this.formData
      const rules = [{ required:true }]
      // 如果有手机号则验证
      if (userMobile) {
        rules.push(validate.mustPhone)
      }
      return rules
    },
    // 是否是 店长/dcc/buddy/master
    isShopWorkRole() {
      const { dealers=[] } = this.userInfo
      const { roleLogos = [] } = dealers[0]
      return roleLogos.some((item) =>
        [ROLES.DCC, ROLES.BUDDY, ROLES.MASTER, ROLES.SHOWOWNER].includes(item)
      )
    },
    // 是否展示保持并跟进按钮
    showSaveAndFollow() {
      // return this.formData.followUserId === this.userInfo.id
      // http://jira.inner.vwdsso.com/browse/SCRMSAL-2535 有顾问 就可以保存并跟进
      return this.formData.followUserId
    },
    getUseType() {
      return getArrToOBj(this.$store.getters.getDictHash(1003) || [])
    },
    getPlateSource() {
      return getArrToOBj(this.$store.getters.getDictHash(1029) || [])
    },
    // 是否展示关联进店客户
    showRoom() {
      const { data=[] } = this.formData.customerSource
      if (Array.isArray(data) && data.findIndex(({ id }) =>{ return ['1753208491511615490'].includes(id) }) !== -1 ) {
        return true
      }
      return false
    },
    dealersList() {
      const { dealers = [] } = this.userInfo
      return dealers
    }
  },
  watch: {
    'formData.userMobile'(value) {
      if (/^1[3456789]\d{9}$/.test(value)) {
        this.baseClueInfoFilled('tel')
      }
    },
    // 监听到店铺信息发生变化 
    selectedShopId: {
      handler(val) {
        // 用户在选中店只有店长权限时，不自动填充分配顾问
        // 当用户在选中店有DCC/buddy/master权限时，则自动填充用户自己
        const { dealers=[], id, name } = this.userInfo
        const roleLogos = dealers.find(({ id }) => { return id === val })?.roleLogos
        if (roleLogos.some((item) =>
          [ROLES.DCC, ROLES.BUDDY, ROLES.MASTER].includes(item)
        )) {
          Object.assign(this.formData, {
            followUserId: id,
            followUserName: name,
          })
        } else {
          Object.assign(this.formData, {
            followUserId: '',
            followUserName: '',
          })
        }
        // 重新校验线索是否存在
        this.getClueDraft()
        this.init()
        if (this.formData.userMobile) this.baseClueInfoFilled('tel')
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    if (Object.keys(this.templateClueInfo).length) {
      const {
        userName,
        userMobile,
        userSex,
        userType,
        companyName,
        wechatInfo = {},
        userWeChatName,
        customerSource = {},
        modelCode,
        modelName,
        seriesCode,
        seriesName,
        followUserId,
        followUserName,
        planTime,
        tryDriveLevel,
        tryDriveLevelName
      } = this.templateClueInfo
      Object.assign(
        this.formData,
        {
          userName,
          userMobile,
          userSex,
          userType,
          companyName,
          userWeChatName,
          wechatInfo,
          customerSource,
          followUserId,
          followUserName,
          planTime,
          tryDriveLevel,
          tryDriveLevelName
        },
        modelCode && seriesCode
          ? { carInfo: { modelCode, modelName, seriesCode, seriesName } }
          : {}
      )
    } else {
      // 默认查询暂存
      this.getClueDraft()
    }
    // 默认渠道选中自然到店
    if (!this.formData.tryDriveLevel){
      this.defaultInStore()
    }
    // 先查询所有标签相关
    this.init()
  },
  methods: {
    async init() {
      this.getAndSetTD() // 标签字典数据
      loading.showLoading()
      let res = await clueServices.getCluesTags({ id: '' })
      // 过滤掉 客户姓名 联系电话 性别 意向车系车型 
      res = res.filter(({ keyCode }) => { return !['userName', 'userMobile', 'userSex', 'intendModel', 'userType', 'companyName', 'channelInfo'].includes(keyCode) })
      loading.hideLoading()
      const infosGN = groupBy(res, 'groupCode')
      const infos = Object.entries(infosGN)
      this.infos = infos.map(arr => {
        let [gName = '', items = []] = arr
        const name = items[0].groupName

        items = items
          .map(i => {
            const { keyName: name, keyCode } = i
            // 如果是兴趣爱好，额外处理
            if (keyCode === 'hobby') {
              // 如果是字符串，则用英文逗号分隔
              if (isString(i.value)) {
                i.value = i.value.split(',')
              }
            }
            return {
              ...i,
              name
            }
          })

        return {
          gName,
          name,
          items
        }
      })
      console.info('this.infos', this.infos)
    },
    async getAndSetTD() {
      loading.showLoading()
      const res = await baseDataServices.getTagsDicsByKey().finally(() => loading.hideLoading())
      loading.hideLoading()
      this.dictionaries = res
    },
    otherPhoneCheck(type) {
      const rules = []
      if (this.formData[type]) {
        rules.push(validate.mustPhone)
      }
      return rules
    },
    // 查询暂存
    getClueDraft() {
      // 跟进记录不暂存
      clueServices.getClueDraft({}).then((res) => {
        const {
          modelCode = '',
          modelName = '',
          seriesCode = '',
          seriesName = '',
          channelOne = '',
          channelOneId = '',
          channelTwo = '',
          channelTwoId = '',
          channelThree = '',
          channelThreeId = '',
          channelFour = '',
          channelFourId = '',
          channelFiveId = '',
          channelFive = '',
          userWeChatName = '',
          userWeChat = '',
          ...query
        } = res
        const customerSource = []
        addItem(customerSource, channelOneId, channelOne)
        addItem(customerSource, channelTwoId, channelTwo)
        addItem(customerSource, channelThreeId, channelThree)
        addItem(customerSource, channelFourId, channelFour)
        addItem(customerSource, channelFiveId, channelFive)
        const displayName = customerSource
          .map(({ text }) => text)
          .filter((val) => val)
          .join(' ')
        Object.assign(
          this.formData,
          query,
          modelCode && seriesCode
            ? { carInfo: { modelCode, modelName, seriesCode, seriesName } }
            : {}, // 意向信息
          displayName
            ? { customerSource: { data: customerSource, displayName } }
            : {}, // 来源
          { wechatInfo: { externalUserid: userWeChat, name: userWeChatName } }, // 微信
          { tryDriveLevelName: query.tryDriveLevel ? this.getDictNameByCode(1012, query.tryDriveLevel) : '' }
        )
      })
      // 拼接来源
      function addItem(arr, id = '', text = '') {
        id && arr.push({ id, text })
      }
    },
    async selectField(field, name, dictType, multiple = false) {
      const _obj = {
        field,
        dictType,
        multiple,
        reasonList: [],
        lastSelect: this.formData[field]
      }
      commonSelector(_obj).then(res => {
        console.info('commonSelector', res)

        if (!multiple) {
          this.formData[field] = res.dictCode
          if (name) this.formData[name] = res.dictName
        } else {
          this.formData[field] = res.map(i => i.dictCode).join(';')
          if (name) this.formData[name] = res.map(i => i.dictName).join(';')
        }
      })
    },
    // 校验品牌车型车型是否完整
    carInfoValidator(value) {
      const { seriesName, modelName } = this.formData.carInfo
      // if ((!seriesName || !modelName) && value) {
      if ((!seriesName) && value) {
        this.carInfoErrMsg = this.$t('请完善车系')
        return false
      }
      this.carInfoErrMsg = ''
      return true
    },
    // 通过电话号码判重
    async baseClueInfoFilled(type) {
      if (this.judgeExists) return false
      this.judgeExists = true
      try {
        loading.showLoading(this.$t('线索验证中'))
        const {
          wechatInfo: { externalUserid: userWechat },
          userMobile,
        } = this.formData
        const record = await clueServices.clueExist({
          userWechat,
          userMobile,
          dealerId: this.selectedShopId || this.userInfo.dealers[0]?.id,
        })
        this.judgeExists = false
        loading.hideLoading()
        this.handleLink(record, type)
      } catch (error) {
        loading.hideLoading()
        this.judgeExists = false
        this.$toast.fail(error.message)
      }
    },
    // 去跟进
    goFollow(id) {
      this.$router.replace({
        path: '/clue-follow',
        query: {
          id,
          replace: true
        },
      })
    },
    // 去详情
    goDetail(id) {
      this.$router.replace({
        path: '/clue-details',
        query: {
          id,
          replace: true
        },
      })
    },
    // 校验结果处理
    handleLink(data, type) {
      const {
        followUserId,
        followUserName,
        id,
        isRecycle,
        followUserRoleCode,
        state
      } = data
      if (!id) return false
      if (isRecycle == 0) { // 未被回收的逻辑
        // 无跟进人 或者战败 或者无效
        const messageObj = {
          '2000020': this.$t('该留资客户已存在本店并无效，请直接认领跟进'),
          '2000045': this.$t('该留资客户已在本店战败，请直接认领跟进')
        }
        if (!followUserId || (['2000020', '2000045'].includes(state) && !followUserId)) {
          this.$dialog
            .confirm({
              title: this.$t('提示'),
              message: ['2000020', '2000045'].includes(state) ? messageObj[state] : this.$t('该线索还未分配跟进人，是否领取 ？'),
              confirmButtonColor: '#B9921A',
              confirmButtonText: ['2000020', '2000045'].includes(state) ? this.$t('立即认领') : this.$t('领取'),
            })
            .then(async () => {
              loading.showLoading()
              const result = await clueServices.receiveClue({ id })
              loading.hideLoading()
              if (result) {
                this.$toast(this.$t('领取成功'))
                setTimeout(() => {
                  ['2000020', '2000045'].includes(state) ? this.goDetail(id) : this.goFollow(id)
                }, 1000)
              }
            })
            .finally(() => {
              type === 'tel'
                ? (this.formData.userMobile = '')
                : (this.formData.wechatInfo = {})
            })
        }
        // 跟进人是自己
        else if (followUserId === this.userInfo.id) {
          this.$dialog
            .confirm({
              title: this.$t('提示'),
              message: this.$t('您正在跟进该客户，无需重复创建，是否去填写跟进记录？'),
              confirmButtonColor: '#B9921A',
              confirmButtonText: this.$t('去填写'),
            })
            .then(() => {
              this.goFollow(id)
            })
            .finally(() => {
              type === 'tel'
                ? (this.formData.userMobile = '')
                : (this.formData.wechatInfo = {})
            })
        }
        // 跟进人是他人
        else if (followUserId && followUserId !== this.userInfo.id) {
          this.$dialog
            .confirm({
              title: this.$t('提示'),
              message: `【${followUserName}${this.$t(
                '】正在跟进该客户，不允许重复创建，您是否去填写跟进记录？'
              )}`,
              confirmButtonColor: '#B9921A',
              confirmButtonText: this.$t('去填写'),
            })
            .then(() => {
              // this.goFollow(data.id)
              this.$router.push({
                path: '/clue-remark',
                query: {
                  id,
                },
              })
            })
            .finally(() => {
              type === 'tel'
                ? (this.formData.userMobile = '')
                : (this.formData.wechatInfo = {})
            })
        }
      } else if (isRecycle == 1) {
        // 回收的逻辑
        if (followUserId === this.userInfo.id) {
          this.$dialog
            .confirm({
              title: this.$t('提示'),
              message: this.$t('由于您未及时跟进，该线索已被回收至门店公共池，不允许重复创建'),
              showCancelButton: false,
              confirmButtonColor: '#B9921A',
              confirmButtonText: this.$t('确认'),
            })
            .then(() => {})
            .finally(() => {
              type === 'tel'
                ? (this.formData.userMobile = '')
                : (this.formData.wechatInfo = {})
            })
        }
        if (followUserId && followUserId !== this.userInfo.id) {
          const roleLogos = this.userInfo.roleLogos || []
          if (roleLogos.includes(followUserRoleCode)) {
            this.$dialog
              .confirm({
                title: this.$t('提示'),
                message: this.$t('店内公共池已存在同号码线索，不允许重复创建，是否直接领取？'),
                confirmButtonColor: '#B9921A',
                confirmButtonText: this.$t(
                  '领取'
                ),
              })
              .then(async () => {
                loading.showLoading()
                const result = await clueServices.receiveRecycle({ id })
                loading.hideLoading()
                if (result) {
                  this.$toast(this.$t('领取成功'))
                  setTimeout(() => {
                    this.goFollow(id)
                  }, 1000)
                }
              })
              .finally(() => {
                type === 'tel'
                  ? (this.formData.userMobile = '')
                  : (this.formData.wechatInfo = {})
              })
          } else {
            this.$dialog
              .confirm({
                title: this.$t('提示'),
                message: `${this.$t(
                  '店内公共池已存在同号码线索，不允许重复创建；由于您无原跟进同角色权限（'
                )}${this.getDictNameByCode(1014, followUserRoleCode)}${this.$t(
                  '），无法直接领取'
                )}`,
                showCancelButton: false,
                confirmButtonColor: '#B9921A',
                confirmButtonText: this.$t(
                  '确认'
                ),
              })
              .then(() => {})
              .finally(() => {
                type === 'tel'
                  ? (this.formData.userMobile = '')
                  : (this.formData.wechatInfo = {})
              })
          }
        }
      }
    },
    // 选择微信联系人
    async selectExternalContact() {
      this.formData.wechatInfo = {}
      window.wx.invoke(
        'selectExternalContact',
        { filterType: 0 },
        async (res) => {
          if (res.err_msg != 'selectExternalContact:ok') return
          const { userIds } = res
          if (!userIds || userIds.length < 1) {
            return
          }
          if (userIds.length > 1) {
            return this.$toast(this.$t('请仅选择一个外部联系人进行绑定'))
          }
          const [userUuid] = userIds
          const crmCustomer = await baseDataServices.getExternalDetail({
            externalUserId: userUuid,
          })
          if (!crmCustomer.externalUserid) {
            this.$toast.fail(this.$t('无效联系人，请重新选择'))
            return
          }
          this.formData.wechatInfo = crmCustomer
        }
      )
    },
    // 添加微信好友
    openAddCustomer() {
      window.wx.invoke('navigateToAddCustomer', {}, function(res) {})
    },
    // 选择来源渠道
    onClueSourceSelect() {
      selectClueSource({ noStrictly: true })
        .then((data) => {
          const res = data.value
          this.formData.customerSource.data = res
          this.$set(
            this.formData.customerSource,
            'displayName',
            res
              .map(({ text }) => text)
              .filter((val) => val)
              .join(' ')
          )
        })
        .catch((err) => console.log(err))
    },
    // 默认选中自然渠道
    async defaultInStore(){
      const res2 = await this.getCascadeSourceList('1').catch(err => console.log(err))
      const target = res2.find(e=> e.channelKey===process.env.VUE_APP_IN_STORE)
      if (target){
        this.$set(this.formData.customerSource,'displayName',target.text)
        this.formData.customerSource.data = [target]
      }
    },
    async getCascadeSourceList(channelId='', level = 0, isLast = false, needLoading=true) {
      if (needLoading) this.loading = true
      const res = await baseDataServices.getChannelListById({ channelId }).catch(err => console.log(err))
      this.loading = false
      let tempArr = res.map(item => {
        const { name: text,channelKey, id, online } = item
        const temp = { text, channelKey, id, online }
        !isLast && (temp.children = [])
        return temp
      })
      return tempArr
    },
    // 选择意向车型
    selectCarModel() {
      const { carInfo: initCarInfo } = this.formData
      selectCar({ initCarInfo })
        .then((carInfo) => {
          if (typeof carInfo.seriesCode === 'undefined') {
            this.$toast(this.$t('请选择正确的车系'))
            return
          }
          // this.formData.carInfo = carInfo
          this.$set(this.formData, 'carInfo', carInfo)
          this.$refs.carInfo.validate()
        })
        .catch(() => {})
    },
    selecNextFollowTime() {
      // this.showNextFollowTime = true
    },
    // 完善客户信息
    async improveUser() {
      if (this.infos.length) this.showMoreInfo = true
    },
    // 收起
    collapse() {
      this.showMoreInfo = false
    },
    // 保存
    async onSubmit(type) {
      // type: 1 保存并跟进 2保存
      let validationRes = true
      validationRes = await this.$refs.createForm
        .validate()
        .then((res) => true)
        .catch((err) => {
          this.$toast(this.$t('请完成必填字段的填写后再提交'))
        })
      if (!validationRes) return
      let labelMap = {}
      this.infos.forEach((item, index) => {
        labelMap = Object.assign({}, labelMap, this.$refs[`editInformation_${index}`][0].EXPORTDATA())
      })
      let params = this.confirmRequestParems()
      params.labelMap = labelMap
      if (this[`submitLoading${type}`]) return false
      this[`submitLoading${type}`] = true
      loading.showLoading()
      clueServices
        .creatClue(params)
        .then((res) => {
          this.$toast.success(this.$t('创建成功'))
          setTimeout(() => {
            this[`submitLoading${type}`] = false
            if (type == 1) {
              this.$router.push({
                path: '/clue-follow',
                query: {
                  id: res.id,
                },
              })
            } else {
              let query = {}
              if (!this.fromClueList){
                query = { fromAssistant:true }
              }
              this.$router.push({
                path: '/clue-list',
                query
              })
            }
          }, 1500)
        })
        .catch(() => {
          this[`submitLoading${type}`] = false
          loading.hideLoading()
        })
    },
    // 提交参数拼接
    confirmRequestParems() {
      const {
        userMobile,
        wechatInfo: { externalUserid: userWeChat, name: userWeChatName },
        userName,
        customerSource: {
          // 来源渠道
          data: permissionList,
        },
        userSex,
        userType,
        companyName,
        carInfo = {},
        followUserName,
        followUserId,
        planTime,
        tryDriveLevel,
        followRemark,
        isMajorCustomer,
        userAddress,
        userCity,
        userCityCode,
        userCounty,
        userCountyCode,
        userProvince,
        userProvinceCode,
        isReplace,
        isParkingSpace,
        vehiclePlate,
        plateSource,
        useType,
      } = this.formData
      const followRequest = { carInfo, storeOfTime: '' }
      const {
        carInfo: { seriesCode, seriesName, modelCode, modelName },
        storeOfTime,
      } = followRequest
      let permission = {}
      permissionList &&
        permissionList.forEach((item, index) => {
          switch (index) {
            case 0:
              permission['channelOneId'] = item.id
              permission['channelOne'] = item.text
              break
            case 1:
              permission['channelTwoId'] = item.id
              permission['channelTwo'] = item.text
              break
            case 2:
              permission['channelThreeId'] = item.id
              permission['channelThree'] = item.text
              break
            case 3:
              permission['channelFourId'] = item.id
              permission['channelFour'] = item.text
              break
            case 4:
              permission['channelFiveId'] = item.id
              permission['channelFive'] = item.text
              break
          }
        })
      const params = {
        ...this.templateClueInfo,
        userMobile,
        userWeChat,
        userWeChatName,
        userName,
        ...permission, // 渠道来源
        userSex,
        userType,
        companyName,
        seriesCode,
        seriesName,
        modelCode,
        modelName,
        followUserName,
        followUserId,
        planTime,
        tryDriveLevel,
        followRemark,
        isMajorCustomer,
        userAddress,
        userCity,
        userCityCode,
        userCounty,
        userCountyCode,
        userProvince,
        userProvinceCode,
        isReplace,
        isParkingSpace,
        vehiclePlate,
        plateSource,
        useType,
      }
      delete params.carInfo
      // 获取完善内容信息（标签）
      return params
    },
    // 暂存
    draftBtn() {
      const { userMobile, userName } = this.formData
      console.log(this.confirmRequestParems())
      const params = this.confirmRequestParems()
      clueServices
        .addClueDraft(params)
        .then((res) => {
          this.$toast.success(this.$t('暂存成功'))
          setTimeout(() => {
            if (window.history.length>1){
              this.$router.go(-1)
            }
          }, 2000)
        })
        .catch()
    },
    distributionNext() {
      this.showDistribution = true
    },
    // 分配
    distributionFilter(params) {
      const { saIdList } = params
      if (saIdList.length) {
        const { name, value } = saIdList[0]
        Object.assign(this.formData, {
          followUserId: value,
          followUserName: name,
        })
      }
    },
    // 打开第三方的页面
    openRoom() {
      this.roomDialogShow = true
    },
    closeRoomDialog(success) {
      this.roomDialogShow = false
      if (success === 'success') {
        this.roomSuccess = this.$t('认领成功')
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    // 返回线索列表时，将缓存清空
    if (['/clue-list'].includes(to.path)) {
      this.$store.commit('app/deleteKeepAlive', 'ClueHome')
    }
    next()
  }
}
</script>
<style scoped lang="less">
@import "../style/commonless.less";
@create-main-color: #3476fe;

.create-clue {
  padding-bottom: 96px;

  /deep/ .common-cell {
    position: relative;
    display: flex;
    font-size: 14px;
    padding: 10px 16px;
    &.required{
      .common-cell-label::after{
        color: #eed484;
        font-size: 15px;
        content: "*";
      }
    }
    &::after {
      content: "";
      position: absolute;
      box-sizing: border-box;
      pointer-events: none;
      right: 16px;
      bottom: 0;
      left: 16px;
      border-bottom: 1px solid #ebedf0;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }

    .common-cell-label {
      color: @black;
      width: fit-content;
      // min-width: 6.2em;
      margin-right: 12px;
      line-height: 32px;
    }

    .no-list {
      line-height: 32px;
    }

    .common-cell-value {
      flex: 1;
    }
  }

  /deep/ .flex-wrap-clue {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;
    justify-content: flex-end;

    .btn-item {
      overflow: hidden;
      margin-bottom: 10px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  /deep/ .btn-item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    height: 24px;
    color: @black;
    background-color: #f5f5f5;
    border-radius: 4px;
    font-size: 12px;

    &.active {
      &:first-child {
        margin-right: 10px;
      }
    }

    &.commonActive {
      color: #b9921a;
      background: rgba(238, 212, 132, 0.45);
    }
  }

  /deep/ .complete {
    display: flex;
    align-items: center;
    justify-content: center;
    color: @yellow-text;
    cursor: pointer;
  }
}
</style>
